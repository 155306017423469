export const APP_ROUTES = {
    HOME: '/',
    ABOUT: '/about',
    SERVICES: '/services',
    PROJECTS: '/projects',
    RESIDENTS_PORTAL: '/residents-portal',
    CONTACT: '/contact',
    PROJECT_DETAILS: '/project-details',
    PROJECT_GALLERY: '/project-gallery',
    BOOK_A_TOUR: '/book-a-tour',
    PROJECT_FLOOR_PLANS: '/project-floor-plans',
}

export const SOCIAL_LINKS = {
    X: 'https://twitter.com/Drfahsa',
    INSTAGRAM: 'https://www.instagram.com/drfahsa',
    LINKEDIN: 'https://www.linkedin.com/company/drfahsa/',
    TIKTOK: 'https://www.tiktok.com/@drfahsa',
}

export const GOOGLE_MAPS_API_KEY = 'AIzaSyA9CSgyoQwB0PBBixrM_mUaxTIrneheNpw'