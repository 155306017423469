import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

import './FloorPlan.css';

const FloorPlan = ({ img, name, area, descriptionList, mode = 'overview', onClick, onClose, showCloseButton = true }) => {
    const [hoveredVilla, setHoveredVilla] = React.useState(0);
    return (
        <div className="floor-plan-container">
            {mode === "overview" ? (
                <>
                    <div
                        onMouseEnter={() => setHoveredVilla(1)}
                        onMouseLeave={() => setHoveredVilla(0)}
                        onClick={onClick}
                        className="floor-plan-image-container">
                        <img src={img} alt={name} />
                    </div>
                    <div className={`floor-plan-name-container ${hoveredVilla === 1 ? 'floor-plan-name-hovered' : ''} `}>
                        <h6
                            onClick={onClick}
                        >{name}</h6>
                    </div>
                    <div className="floor-plan-area-container">
                        <p>{area}</p>
                    </div>
                </>
            ) : (
                <Row className='floor-plan-details'>
                    <Col xs={12} md={12} lg={9} className='order-first order-lg-last'>
                        {showCloseButton && (
                            <div className="close-button" onClick={onClose}> <FaTimes /> </div>
                        )}
                        <div className="floor-plan-image-container individual">
                            <img src={img} alt={name} />
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={3} className='order-last order-lg-first floor-plan-details-text'>
                        <div className="floor-plan-name-container individual">
                            <h5>{name}</h5>
                        </div>
                        <div className="floor-plan-description-container individual">
                            <ul>
                                <li>{area}</li>
                                {descriptionList.map((description, index) => (
                                    <li key={index}>{description}</li>
                                ))}
                            </ul>
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default FloorPlan;
