const images = require.context("../assets/projects/OfficesRyiadh/images", false, /.*\.(png|jpe?g|svg)$/);
const thumbnailImages = require.context("../assets/projects/OfficesRyiadh/thumbnails", false, /.*\.(png|jpe?g|svg)$/);
const importedImages = images.keys().map(images);
const importedThumbnailImages = thumbnailImages.keys().map(thumbnailImages);

export const OfficesRyiadh = {
    id: "RyiadhOffices",
    name: "Adminstrative Offices",
    residential_Unit_Type: "Offices",
    scope: "Design",
    status: "Rent",
    starting_price: null,
    starting_price_text: null,
    number_of_bedrooms: null,
    location: null,
    area: "790 m²",
    stats: {},
    nearby_description: "",
    team: [
        "Abdulaziz Alkassim",
        "Deema Alkassim",
        "Abdulrahman Alshawbaki",
    ],
    main_images: [
        importedImages[0],
        importedImages[1],
        importedImages[2],
    ],
    text_sections: [
        "The Office aspires to not merely function as an administrative hub, but as a global nexus for cultural exchange and dialogue. It aims to bridge the gap between Saudi Arabian heritage and the wider world, fostering understanding through the intricate narratives woven into its very fabric.",
        "At the heart of this ambitious vision meticulously designed to embody the Centre's core values, the project will stand as a testament to their rich cultural tapestry, their unwavering commitment to intercultural bridges, and their steadfast trust in both the client's mission and the potential for global harmony.",
        "The office transcends mere functionality, immersing visitors in the very essence of Saudi culture. It seamlessly intertwines the rhythms of a bygone era, evident in user behavior and spatial nuances, with the dynamism of modernity. This curated experience not only simulates the past but serves as a potent embodiment of the client's core values.",
        "The color palette itself becomes a silent narrative, with hues like regal purple, radiant gold, soothing moss green, and serene blue echoing the client’s aspirations and mirroring elements of the surrounding environment. Each color, carefully chosen, becomes a brushstroke in the grand portrait of the Centre's identity. The golden yellow speaks to the buildings at past times and our sands, the moss green and lavender speaks to our vegetation, and the blue resembles our clear skies and seas.",
        "Local design elements have been introduced such as arches, motifs, textile and materials. As well as using local vegetation, and rattan in ceilings to mimic building structures and design elements in that time.",
        "The design focuses on crafting meaningful spaces directly linked to cultural roots. The spaces are distributed according to client and capacity, hence creating unique spaces for users.",
    ],
    section_images: {},
    plans_cover: null,
    plnas_description: "",
    location_dev: {},
    images: importedImages.map((image, index) => {
        return {
            image: image,
            description: "",
            thumbnail: importedThumbnailImages[index],
        }
    }, []),

    hasPlansOverlay: false,
    hasPlans: false,
    hasLocation: false,
    forSale: false,
    plans: {},
    villaInof: {},
    floorPlans: [],
};