import FirstFloor from "../assets/projects/StayAlNakheel/floorplans/1.png";
import SecondFloor from "../assets/projects/StayAlNakheel/floorplans/2.png";

const images = require.context("../assets/projects/StayAlNakheel/images", false, /.*\.(png|jpe?g|svg)$/);
const thumbnailImages = require.context("../assets/projects/StayAlNakheel/thumbnails", false, /.*\.(png|jpe?g|svg)$/);
const importedImages = images.keys().map(images);
const importedThumbnailImages = thumbnailImages.keys().map(thumbnailImages);


export const StayAlNakheel_ar = {
    id: "StayAlNakheel",
    name: "ستاي النخيل",
    residential_Unit_Type: "شقق",
    scope: "تطوير",
    status: "مؤجر",
    starting_price: null,
    starting_price_text: null,
    number_of_bedrooms: null,
    location: "النخيل",
    area: "5,500 m²",
    stats: {
        units: {
            value: 33,
            unit: "",
        },
        floors_in_unit: {
            value: 1,
            unit: "",
        },
        built_up_area_per_unit: {
            prefix: "حتى",
            value: 110,
            unit: "م²",
        },
        total_built_up_area: {
            value: "5,500",
            unit: "م²",
        }
    },
    nearby_description: "يقع المشروع بشكل مثالي عند تقاطع طريق الدائري الشمالي وطريق الأمير تركي الأول، مما يوفر سهولة ومرونة في التنقل بالسيارات. وبالإضافة إلى ذلك، يبعد العقار مسافة 600 متر فقط عن جامعة الملك سعود، و900 متر عن المدينة الرقمية، و2 و3 كيلومترات فقط عن كل من مركز الملك عبد الله المالي وبوابة الدرعية. ويوفر هذا الموقع المركزي للسكان والزوار سهولة الوصول الفوري إلى مراكز الأعمال الرئيسية والمؤسسات الأكاديمية.",
    team: [
        "عبدالعزيز القاسم",
        "ديمه القاسم",
        "عبدالرحمن الشوبكي",
    ],
    main_images: [
        importedImages[6],
        importedImages[2],
        importedImages[3],
    ],
    text_sections: [
        "ستاي هي شركة في مجال تطوير العقارات السكنية وتأجيرها، منذ عام ٢٠١٥ وتعمل على توفير مجمعات سكنية متكاملة بمرافق وخدمات استثنائية واختيارات متنوعة من الشقق المؤثثة ونصف المفروشة بفترة تأجير مناسبة.",
        "ستاي في النخيل، مشروع ريادي يقدم تجربة معيشية مميزة تلبي احتياجات السكن العصرية بتصميم أنيق. يقع المشروع بالقرب من أكبر مراكز ومقرات الشركات الرئيسية في الرياض، مما يوفر للسكان سهولة الوصول إلى أبرز المعالم. يركز المشروع على توفير مساحات عملية وجمالية تكمل احتياجات المنزل العصري، مع مراعاة معايير الاستدامة.",
        "يتميز تصميم المشروع بواجهة مزدوجة تبرز عن النمط المعماري في المشاريع المجاورة، وتعكس نهج ستاي في تطبيق معايير الاستدامة في التصميم. وعند دخول المبنى، يستقبل السكان بفناء خارجي يفصل بين الوحدات ليخلق مساحات مضيئة وتتوفر من جميع الطوابق الثلاثة للمبنى إطلالات للفناء. يوفر المشروع 33 وحدة سكنية مستغلة مساحاتها لزيادة راحة السكان ورفاهيتهم. تتراوح الشقق بين غرفة وغرفتين نوم، وتتميز بغرفة معيشة مريحة ومنطقة لتناول الطعام ومطبخ وحمامات. وللتسلية والاسترخاء، يوفر المشروع مرافق ترفيهية مثل مسبح على السطح ومناطق شواء وجلسات خارجية. بالإضافة إلى صالة ضيافة. كما يوفر السطح إطلالات على المناطق المحيطة والحي النابض.",
    ],
    section_images: {},
    plans_cover: FirstFloor,
    plnas_description: "تستفيد المخططات من المساحات بكفاءة عالية، وتوفر مناطق محددة بوضوح للمعيشة وتناول الطعام والطهي والاسترخاء. تم تصميم كل مساحة لتكون قابلة للتغير ومتعددة الوظائف، لتلبية احتياجات وأنشطة متنوعة.",
    location_dev: {
        latitude: 24.74425,
        longitude: 46.6135,
        google_maps_url: "https://maps.app.goo.gl/PjMfVLnzvYk8s5jT7",
    },
    images: importedImages.map((image, index) => {
        return {
            image: image,
            description: "",
            thumbnail: importedThumbnailImages[index],
        }
    }, []),
    hasPlansOverlay: false,
    hasPlans: true,
    hasLocation: true,
    forSale: false,
    plans: {},
    villaInof: {},
    globalDescriptionList: [
        "1 منطقة معيشة",
        "2 منطقة لتناول الطعام",
        "3 مطبخ",
        "4 مرحاض",
        "5 منطقة الغسيل",
        "6 غرف نوم مزدوجة",
    ],
    floorPlans: [
        {
            name: "الطابق الأول",
            img: FirstFloor,
            area: "",
            descriptionList: [],
        },
        {
            name: "الطابق الثاني",
            img: SecondFloor,
            area: "",
            descriptionList: [],
        },
    ],
};
