const images = require.context("../assets/projects/DrfahOffice/images", false, /.*\.(png|jpe?g|svg)$/);
const thumbnailImages = require.context("../assets/projects/DrfahOffice/thumbnails", false, /.*\.(png|jpe?g|svg)$/);
const importedImages = images.keys().map(images);
const importedThumbnailImages = thumbnailImages.keys().map(thumbnailImages);

export const DrfahOffice = {
    id: "DrfahOffice",
    name: "Adminstrative Offices",
    residential_Unit_Type: "Offices",
    scope: "Design & Develop",
    status: "Rent",
    starting_price: null,
    starting_price_text: null,
    number_of_bedrooms: null,
    location: "Al Raed",
    area: "200 m²",
    stats: {},
    nearby_description: "Located in Al Raed District, on King Abdullah Street.",
    team: [
        "Abdulaziz Alkassim",
        "Deema Alkassim",
    ],
    main_images: [
        importedImages[3],
        importedImages[1],
        importedImages[2],
    ],
    text_sections: [
        "The office, designed and completed in 2022, showcases a modern and efficient workspace. Sunlight floods the open floor plan through large windows. Hidden doors lead to office service areas, such as the kitchen and w.c.'s. Minimalist design dominates, with clean lines and uncluttered surfaces promoting focus and productivity.",
        "The use of natural wood for desk accents and potted plants throughout the office fosters a sense of warmth and connection to nature. Marble tables in select areas add a touch of sophistication and color. The overall design and color palette prioritize functionality and employee well-being, ensuring a productive and comfortable work environment.",
        "The office reflects its values and the company's commitment to a collaborative, modern, and efficient work environment.",
    ],
    section_images: {},
    plans_cover: null,
    plnas_description: "",
    location_dev: {
        latitude: 24.71125,
        longitude: 46.6315833333,
        google_maps_url: "https://maps.app.goo.gl/Rn4uecoE7dpEpREQ9",
    },
    images: importedImages.map((image, index) => {
        return {
            image: image,
            description: "",
            thumbnail: importedThumbnailImages[index],
        }
    }, []),
    hasPlansOverlay: false,
    hasPlans: false,
    hasLocation: true,
    forSale: false,
    plans: {},
    villaInof: {},
    floorPlans: [],
};