import A1 from "../assets/projects/StayAlYasmin/floorplans/A1-min.png";
import A2 from "../assets/projects/StayAlYasmin/floorplans/A2-min.png";
import A3 from "../assets/projects/StayAlYasmin/floorplans/A3-min.png";
import B1 from "../assets/projects/StayAlYasmin/floorplans/B1-min.png";
import B2 from "../assets/projects/StayAlYasmin/floorplans/B2-min.png";
import B3 from "../assets/projects/StayAlYasmin/floorplans/B3-min.png";
import C1 from "../assets/projects/StayAlYasmin/floorplans/C1-min.png";
import C2 from "../assets/projects/StayAlYasmin/floorplans/C2-min.png";
import D1 from "../assets/projects/StayAlYasmin/floorplans/D1-min.png";
import D2 from "../assets/projects/StayAlYasmin/floorplans/D2-min.png";

const images = require.context("../assets/projects/StayAlYasmin/images", false, /.*\.(png|jpe?g|svg)$/);
const thumbnailImages = require.context("../assets/projects/StayAlYasmin/thumbnails", false, /.*\.(png|jpe?g|svg)$/);
const importedImages = images.keys().map(images);
const importedThumbnailImages = thumbnailImages.keys().map(thumbnailImages);

export const StayAlYasmin_ar = {
    id: "StayAlYasmin",
    name: "ستاي الياسمين",
    residential_Unit_Type: "شقق",
    scope: "تطوير",
    status: "مؤجر",
    starting_price: null,
    starting_price_text: null,
    number_of_bedrooms: null,
    location: "الياسمين",
    area: "4,200 m²",
    stats: {
        units: {
            value: 33,
            unit: "",
        },
        built_up_area_per_unit: {
            prefix: "حتى",
            value: 190,
            unit: "م²",
        },
        floors_in_unit: {
            value: [2, 3],
            unit: "",
        },
        total_built_up_area: {
            value: "4,200",
            unit: "م²",
        },
    },
    nearby_description: "يقع المشروع بموقع استراتيجي داخل حي الياسمين، ويوفر سهولة الوصول إلى الوجهات الرئيسية. يبعد مطار الملك خالد الدولي مسافة 15 كيلومتر، بينما يمكن الوصول إلى بارك أفينيو مول بسهولة على مسافة 8 كيلومترات. بالإضافة إلى ذلك، يتمتع المشروع بقرب من الرياض فرونت، وهو مركز أعمال بارز يقع على بعد 8 كيلومترات فقط. ",
    team: [
        "عبدالعزيز القاسم",
    ],
    main_images: [
        importedImages[4],
        importedImages[2],
        importedImages[1],
    ],
    text_sections: [
        "ستاي هي شركة في مجال تطوير العقارات السكنية وتأجيرها، منذ عام ٢٠١٥ وتعمل على توفير مجمعات سكنية متكاملة بمرافق وخدمات استثنائية واختيارات متنوعة من الشقق المؤثثة ونصف المفروشة بفترة تأجير مناسبة.",
        "223Stories الأول من فروع ستاي، مشروع ريادي يقدم تجربة معيشية مميزة تلبي احتياجات السكن العصرية بتصميم أنيق. يقع المشروع في شمال الرياض. يركز المشروع على توفير مساحات عملية وجمالية تكمل احتياجات المنزل العصري، مع مراعاة معايير الاستدامة.",
        "عند دخول المبنى، يستقبل السكان بفناء خارجي يفصل بين الوحدات ليخلق مساحات مضيئة وتتوفر من جميع الطوابق الثلاثة للمبنى إطلالات للفناء. يوفر المشروع 33 وحدة سكنية مستغلة مساحاتها لزيادة راحة السكان ورفاهيتهم. تتراوح الشقق بين غرفة وغرفتين وثلاث غرف نوم، وتتميز بغرفة معيشة مريحة ومنطقة لتناول الطعام ومطبخ وحمامات. وللتسلية والاسترخاء، يوفر المشروع مرافق ترفيهية مثل مسبح على السطح ومناطق شواء وجلسات خارجية. بالإضافة إلى صالة ضيافة. كما يوفر السطح إطلالات على المناطق المحيطة والحي النابض.",
    ],
    section_images: {},
    plans_cover: A1,
    plnas_description: "تستفيد المخططات من المساحات بكفاءة عالية، وتوفر مناطق محددة بوضوح للمعيشة وتناول الطعام والطهي والاسترخاء. تم تصميم كل مساحة لتكون قابلة للتغير ومتعددة الوظائف، لتلبية احتياجات وأنشطة متنوعة.",
    location_dev: {
        latitude: 24.8275555556,
        longitude: 46.6301666667,
        google_maps_url: "https://maps.app.goo.gl/jjufDheFeTR7mkxc6",
    },
    images: importedImages.map((image, index) => {
        return {
            image: image,
            description: "",
            thumbnail: importedThumbnailImages[index],
        }
    }, []),
    hasPlansOverlay: false,
    hasPlans: true,
    hasLocation: true,
    forSale: false,
    plans: {},
    villaInof: {},
    globalDescriptionList: null,
    floorPlans: [
        {
            name: "A - الطابق الأرضي",
            img: A1,
            area: "",
            descriptionList: [
                "1 منطقة معيشة",
                "2 منطقة لتناول الطعام",
                "3 مطبخ",
                "4 مرحاض",
                "الدرج 5",
                "6 مصعد",
                "7 غرف نوم",
                "8 غرف نوم مفردة",
                "9 مناطق خارجية",
                "10 غرفة خادمة",
            ],
        },
        {
            name: "A - الطابق الأول",
            img: A2,
            area: "",
            descriptionList: [
                "1 منطقة معيشة",
                "2 منطقة لتناول الطعام",
                "3 مطبخ",
                "4 مرحاض",
                "الدرج 5",
                "6 مصعد",
                "7 غرف نوم",
                "8 غرف نوم مفردة",
                "9 مناطق خارجية",
                "10 غرفة خادمة",
            ],
        },
        {
            name: "A - الطابق الثاني",
            img: A3,
            area: "",
            descriptionList: [
                "1 منطقة معيشة",
                "2 منطقة لتناول الطعام",
                "3 مطبخ",
                "4 مرحاض",
                "الدرج 5",
                "6 مصعد",
                "7 غرف نوم",
                "8 غرف نوم مفردة",
                "9 مناطق خارجية",
                "10 غرفة خادمة",
            ],
        },
        {
            name: "B - الطابق الأرضي",
            img: B1,
            area: "",
            descriptionList: [
                "1 منطقة معيشة",
                "2 منطقة لتناول الطعام",
                "3 مطبخ",
                "4 مرحاض",
                "5 الدرج",
                "6 مصعد",
                "7 غرف نوم",
                "8 غرف نوم مفردة",
                "10 غرفة خادمة",
            ],
        },
        {
            name: "B - الطابق الأول",
            img: B2,
            area: "",
            descriptionList: [
                "1 منطقة معيشة",
                "2 منطقة لتناول الطعام",
                "3 مطبخ",
                "4 مرحاض",
                "5 الدرج",
                "6 مصعد",
                "7 غرف نوم",
                "8 غرف نوم مفردة",
                "10 غرفة خادمة",
            ],
        },
        {
            name: "B - الطابق الثاني",
            img: B3,
            area: "",
            descriptionList: [
                "1 منطقة معيشة",
                "2 منطقة لتناول الطعام",
                "3 مطبخ",
                "4 مرحاض",
                "5 الدرج",
                "6 مصعد",
                "7 غرف نوم",
                "8 غرف نوم مفردة",
                "10 غرفة خادمة",
            ],
        },
        {
            name: "C - الطابق الأرضي",
            img: C1,
            area: "",
            descriptionList: [
                "1 منطقة معيشة",
                "2 منطقة لتناول الطعام",
                "3 مطبخ",
                "4 مرحاض",
                "5 الدرج",
                "6 مصعد",
                "7 غرف نوم",
            ],
        },
        {
            name: "C - الطابق الأول",
            img: C2,
            area: "",
            descriptionList: [
                "1 منطقة معيشة",
                "2 منطقة لتناول الطعام",
                "3 مطبخ",
                "4 مرحاض",
                "5 الدرج",
                "6 مصعد",
                "7 غرف نوم",
            ],
        },
        {
            name: "D - الطابق الأرضي",
            img: D1,
            area: "",
            descriptionList: [
                "1 Living Area",
                "2 Dining Area",
                "3 Kitchen",
                "4 W.C",
                "5 Stairs ",
                "6 Lift ",
                "7 bedroom",
            ],
        },
        {
            name: "D - الطابق الأول",
            img: D2,
            area: "",
            descriptionList: [
                "1 منطقة معيشة",
                "2 منطقة لتناول الطعام",
                "3 مطبخ",
                "4 مرحاض",
                "5 الدرج",
                "6 مصعد",
                "7 غرف نوم",
            ],
        },
    ],
};