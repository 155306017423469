import SingleStay0 from "../assets/projects/StayHittin/floorplans/Single Stay level 0-min.png";
import SingleStay1 from "../assets/projects/StayHittin/floorplans/Single Stay level 1-min.png";
import DoubleStay0 from "../assets/projects/StayHittin/floorplans/Double Stay level 0-min.png";
import DoubleStay1 from "../assets/projects/StayHittin/floorplans/Double Stay level 1-min.png";
import FamilyStay0 from "../assets/projects/StayHittin/floorplans/Family Stay level 0-min.png";
import FamilyStay1 from "../assets/projects/StayHittin/floorplans/Family Stay level 1-min.png";
import FamilyStay2 from "../assets/projects/StayHittin/floorplans/Family Stay level 2-min.png";

import digaram from "../assets/projects/StayHittin/diagrams/diagram.gif";

const images = require.context("../assets/projects/StayHittin/images", false, /.*\.(png|jpe?g|svg)$/);
const thumbnailImages = require.context("../assets/projects/StayHittin/thumbnails", false, /.*\.(png|jpe?g|svg)$/);
const importedImages = images.keys().map(images);
const importedThumbnailImages = thumbnailImages.keys().map(thumbnailImages);


export const StayHittin_ar = {
    id: "StayHittin",
    name: "ستاي حطين",
    residential_Unit_Type: "شقق",
    scope: "تصميم و تطوير",
    status: "مؤجر",
    starting_price: null,
    starting_price_text: null,
    number_of_bedrooms: 3,
    location: "حطين",
    area: "2,165 m²",
    stats: {
        units: {
            value: 16,
            unit: "",
        },
        built_up_area_per_unit: {
            prefix: "حتى",
            value: 198,
            unit: "م²",
        },
        floors_in_unit: {
            value: [2, 3],
            unit: "",
        },
        total_built_up_area: {
            value: "2,165",
            unit: "م²",
        }
    },
    nearby_description: "",
    team: [
        "عبدالعزيز القاسم",
        "ديمه القاسم",
        "عبدالرحمن الشوبكي",
    ],
    main_images: [
        importedImages[1],
        importedImages[0],
        importedImages[2],
    ],
    text_sections: [
        "ستاي هي شركة في مجال تطوير العقارات السكنية وتأجيرها، منذ عام ٢٠١٥ وتعمل على توفير مجمعات سكنية متكاملة بمرافق وخدمات استثنائية واختيارات متنوعة من الشقق المؤثثة ونصف المفروشة بفترة تأجير مناسبة.",
        "ستاي في حطين، مشروع ريادي يقدم تجربة معيشية مميزة تلبي احتياجات السكن العصرية بتصميم أنيق. يقع المشروع بالقرب من أكبر مراكز الترفيه ومقرات الشركات والمراكز الرئيسية في الرياض، مما يوفر للسكان سهولة الوصول إلى أبرز المعالم. يركز المشروع على توفير مساحات عملية وجمالية تكمل احتياجات المنزل العائلي العصري، مع مراعاة معايير الاستدامة.",
        "يتميز تصميم المشروع بواجهة مزدوجة تبرز عن النمط المعماري في المشاريع المجاورة، وتعكس نهج ستاي في تطبيق معايير الاستدامة في التصميم. وعند دخول المبنى، يستقبل السكان بفناء خارجي يفصل بين الوحدات ليخلق مساحات مضيئة وتتوفر من جميع الطوابق الثلاثة للمبنى إطلالات للفناء. يوفر المشروع ١٦ وحدة سكنية مستغلة مساحاتها لزيادة راحة السكان ورفاهيتهم. تتراوح الشقق بين غرفة وغرفتين وثلاث غرف نوم، وتتميز بغرفة معيشة مريحة ومنطقة لتناول الطعام ومطبخ وحمامات. وللتسلية والاسترخاء، يوفر المشروع مرافق ترفيهية مثل مسبح على السطح ومناطق شواء وجلسات خارجية. بالإضافة إلى مساحات عمل مشتركة وصالة ضيافة. كما يوفر السطح إطلالات على مناطق الترفيه المحيطة والأحياء النابضة.",
    ],
    section_images: {
        1: [digaram],
    },


    plans_cover: FamilyStay1,
    plnas_description: "تستفيد المخططات من المساحات بكفاءة عالية، وتوفر مناطق محددة بوضوح للمعيشة وتناول الطعام والطهي والاسترخاء. تم تصميم كل مساحة لتكون قابلة للتغير ومتعددة الوظائف، لتلبية احتياجات وأنشطة متنوعة.",
    location_dev: {},
    images: importedImages.map((image, index) => {
        return {
            image: image,
            description: "",
            thumbnail: importedThumbnailImages[index],
        }
    }),
    hasPlansOverlay: false,
    hasPlans: true,
    hasLocation: false,
    forSale: false,
    plans: {},
    villaInof: {},
    globalDescriptionList: [
        "1. غرفة المعيشة",
        "2. منطقة الطعام",
        "3. المطبخ",
        "4. الدرج",
        "5. غرفة الغسيل",
        "6. المرحاض",
        "7. غرفة النوم",
        "8. المناطق الخارجية",
    ],
    floorPlans: [
        {
            name: "الطابق الأرضي Single Stay",
            img: SingleStay0,
            area: "",
            descriptionList: [],
        },
        {
            name: "الطابق الأول Single Stay",
            img: SingleStay1,
            area: "",
            descriptionList: [],
        },
        {
            name: "الطابق الأرضي Double Stay",
            img: DoubleStay0,
            area: "",
            descriptionList: [],
        },
        {
            name: "الطابق الأول Double Stay",
            img: DoubleStay1,
            area: "",
            descriptionList: [],
        },
        {
            name: "الطابق الأرضي Family Stay",
            img: FamilyStay0,
            area: "",
            descriptionList: [],
        },
        {
            name: "الطابق الأول Family Stay",
            img: FamilyStay1,
            area: "",
            descriptionList: [],
        },
        {
            name: "الطابق الثاني Family Stay",
            img: FamilyStay2,
            area: "",
            descriptionList: [],
        },
    ],
};