const images = require.context("../assets/projects/OfficesRyiadh/images", false, /.*\.(png|jpe?g|svg)$/);
const thumbnailImages = require.context("../assets/projects/OfficesRyiadh/thumbnails", false, /.*\.(png|jpe?g|svg)$/);
const importedImages = images.keys().map(images);
const importedThumbnailImages = thumbnailImages.keys().map(thumbnailImages);

export const OfficesRyiadh_ar = {
    id: "RyiadhOffices",
    name: "مكاتب إدارية",
    residential_Unit_Type: "مكاتب",
    scope: "تصميم",
    status: "مؤجر",
    starting_price: null,
    starting_price_text: null,
    number_of_bedrooms: null,
    location: null,
    area: "790 m²",
    stats: {},
    nearby_description: "",
    team: [
        "عبدالعزيز القاسم",
        "ديمه القاسم",
        "عبدالرحمن الشوبكي",
    ],
    main_images: [
        importedImages[0],
        importedImages[1],
        importedImages[2],
    ],
    text_sections: [
        "يطمح المكتب إلى تجاوز كونِه مجرد مركز إداري، ليصبح نقطة تواصل عالمية لتبادل الثقافة والحوار. ويهدف إلى سد الفجوة بين تراث المملكة العربية السعودية والعالم بالإضافة إلى تعزيز التفاهم من خلال الروايات المعقدة المنسوجة في نسيج المكتب ذاته.",
        "في قلب هذه الرؤية الطموحة، المصممة بعناية لتجسيد القيم الأساسية للمركز، يقف المشروع كشاهد على النسيج الثقافي الغني للمملكة، والتزامها الثابت ببناء جسور بين الثقافات، وثقتها الراسخة في مهمة المركز وإمكانية تحقيق الانسجام العالمي.",
        "يتجاوز المكتب مجرد الوظيفة الإدارية، ليغمر الزوار في جوهر الثقافة السعودية. يمزج التصميم بسلاسة بين إيقاعات الماضي، التي تتجلى في سلوك المستخدمين والفروق المكانية، وبين ديناميكية الحداثة. هذه التجربة المرتبة لا تحاكي الماضي فحسب، بل تعمل أيضا كتجسيد قوي للقيم الأساسية للمركز.",
        "تصبح لوحة الألوان نفسها سردا صامتا، حيث تعكس درجات ألوان مثل الأرجواني الملكي والذهبي والأخضر والازرق الهادئ تطلعات المركز وتعكس عناصر البيئة المحيطة. كل لون، تم اختياره بعناية، يصبح بمثابة لمسة فرشاة في الصورة الكبيرة لهوية المركز. يشير اللون الأصفر الذهبي إلى المباني في الأوقات الماضية ورمالنا، ويتحدث اللون الأخضر واللافندر عن نباتاتنا، ويشبه الأزرق سمائنا وبحارنا الصافية.",
        "تم إدخال عناصر تصميم محلية مثل الأقواس والزخارف والمنسوجات والمواد، بالإضافة إلى استخدام النباتات المحلية والرتان في الأسقف لتقليد هياكل المباني وعناصر التصميم في ذلك الوقت.",
        "يركز التصميم على صياغة مساحات ذات مغزى ترتبط مباشرة بالجذور الثقافية. تم توزيع المساحات حسب عدد الموظفين واحتياجاتهم، مما يخلق مساحات فريدة للمستخدمين.",
    ],
    section_images: {},
    plans_cover: null,
    plnas_description: "",
    location_dev: {},
    images: importedImages.map((image, index) => {
        return {
            image: image,
            description: "",
            thumbnail: importedThumbnailImages[index],
        }
    }, []),
    hasPlansOverlay: false,
    hasPlans: false,
    hasLocation: false,
    forSale: false,
    plans: {},
    villaInof: {},
    floorPlans: [],
};