import diagram from "../assets/projects/AlUyaynahRetreat/diagrams/diagram.gif";
import floor from "../assets/projects/AlUyaynahRetreat/floorplans/floor.png";

const images = require.context("../assets/projects/AlUyaynahRetreat/images", false, /.*\.(png|jpe?g|svg)$/);
const thumbnailImages = require.context("../assets/projects/AlUyaynahRetreat/thumbnails", false, /.*\.(png|jpe?g|svg)$/);
const importedImages = images.keys().map(images);
const importedThumbnailImages = thumbnailImages.keys().map(thumbnailImages);


export const AlUyaynahRetreat_ar = {
    id: "AlUyaynahRetreat",
    name: "مزرعة العيينة",
    residential_Unit_Type: "مزرعة",
    scope: "تصميم و تطوير",
    status: "مباع",
    starting_price: null,
    starting_price_text: null,
    number_of_bedrooms: null,
    location: "العيينة",
    area: "10,000 m²",
    stats: {
        area: {
            value: "10,000",
            unit: "م²"
        },
        total_built_up_area: {
            value: 450,
            unit: "م²"
        },
    },
    nearby_description: "",
    team: [
        "عبدالعزيز القاسم",
        "ديمه القاسم",
    ],
    main_images: [
        importedImages[4],
        importedImages[1],
        importedImages[2],
    ],
    text_sections: [
        "مشروع مأوى للعميل، صُمم لخلق تجربة داخلية/خارجية تستفيد من نقاط القوة والفرص في الموقع، مع مفاداة نقاط الضعف والتهديدات. تقسم الملكية المساحات حسب الوظائف، ليصل إجمالي مساحة البناء إلى 450 متراً مربعاً.",
        "تقبل الواجهة المصمتة المستخدمين وتحميهم من الشمس والسماء الملوثة بالضوء والمباني المجاورة. تناقض الواجهة التشجير المحيط، وتوجه التركيز نحو المشهد الطبيعي بدلاً من العناصر المضافة. تسقف المظلة مدخل سري من بعد الدرج، مما يدعو إلى استكشاف المنطقة ويعزز ارتفاعها البسيط شعور التواضع. تناقض هذا الشعور المساحات ذات الجدران الزجاجية بالداخل لأنها تخلق إحساسًا بالاتساع والاتصال بالخارج. تم وضع المظلة بناءً على مسار الشمس، حيث تلقي ظل يتغير بحسب ساعات اليوم على الواجهة.",
        "فناء داخلي بشجرة يفصل بين أماكن المعيشة على جانبيه. غرفة المعيشة الرئيسية تصور الاحتفال بالتراث والثقافة السعودية، يليها منطقة لتناول الطعام. تندمج الأسقف البارزة معًا خلف الشجرة، لتخيط المساحتين في كيان واحد مما يخلق انتقالًا سلسًا بين المعيشة الداخلية والخارجية. كما أنها توفر الظل والحماية. تقع المساحات بشكل استراتيجي في الاتجاه الشمالي الشرقي لاستغلال صف أشجار النخيل وإطلالة تلال العيينة. محاولاً خلق إحساس بالسكينة والانفتاح والاتصال بالطبيعة.",
        "يقع المسبح على إطار العين نفسه، ليخلق إحساسًا بالتوازن والانسجام. يعمل المسبح أيضًا على تأكيد على اسم العيينة، الذي يعني عين الماء. ويتناقض مع التلال في اللون والطبيعة، منشأً مساحة ديناميكية ومثيرة بصريًا. يتدفق تيار الهواء الذي يبرده المسبح إلى الفناء ويخرج من خلال فتحة السقف محققًا تهوية طبيعية. يفيض المسبح بطريقة الـ(اوفرفلوoverflow ) لإنشاء شلال مائي لمنطقة الجلوس الخارجية أسفله. يخلق الشلال والنباتات واحة استوائية لمنطقة الجلوس الخارجية. توفر النباتات أيضًا الظل والخصوصية، بينما يصنع الشلال إحساسًا بالحركة والصوت.",
        "لضمان عدم وجود تهديدات لتجربة المستخدم، تم زراعة أشجار بشكل غلاف حول سور المشروع."
    ],
    section_images: {
        1: [diagram],
    },
    plans_cover: floor,
    plnas_description: "يركز التصميم على تعزيز تفاعل المستخدمين مع الطبيعية. ويتحقق ذلك من خلال تصميم يشجع على التنقل بين المساحات عبر مسارات خارجية. يعزز هذا النهج ارتباطًا أعمق للخارج ويحسن تجربة المستخدم بشكل عام.",
    location_dev: {},
    images: importedImages.map((image, index) => {
        return {
            image: image,
            description: "",
            thumbnail: importedThumbnailImages[index],
        }
    }, []),
    hasPlansOverlay: false,
    hasPlans: true,
    hasLocation: false,
    forSale: false,
    plans: {},
    villaInof: {},
    floorPlans: [
        {
            name: "المبنى الرئيسي",
            img: floor,
            area: "",
            descriptionList: [
                "المبنى الرئيسي",
                "1 منطقة معيشة",
                "2 صالة ومنطقة لتناول الطعام",
                "3 مرحاض",
                "",
                "المناطق الخارجية:",
                "4 مطبخ خارجي",
                "5 مسبح",
                "6 مخزن",
            ],
        },
    ],
};