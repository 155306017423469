import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { SOCIAL_LINKS } from '../../const/const';
import propertyawards from '../../assets/AR_24-WebBadge-3.png';
import './footer.css';

const Footer = ({ t }) => {
    return (
        <footer className='text-center pt-4 mt-auto footer'>
            <Container>
                <Row className='justify-content-center mb-2'>
                    <Col md={3} className='d-flex justify-content-start'>
                        <a href='https://propertyawards.net/' target='_blank' rel="noreferrer" className='nav-link-no-arrow'>
                            <img src={propertyawards} alt='propertyawards' className='img-fluid footer-img' />
                        </a>
                    </Col>

                    <Col md={6}>
                        <a href={SOCIAL_LINKS.INSTAGRAM} target='_blank' rel="noreferrer" className='nav-link-no-arrow'>
                            {t('footer.instagram')}
                        </a>

                        <a href={SOCIAL_LINKS.LINKEDIN} target='_blank' rel="noreferrer" className='nav-link-no-arrow'>
                            {t('footer.linkedin')}
                        </a>

                        <a href={SOCIAL_LINKS.TIKTOK} target='_blank' rel="noreferrer" className='nav-link-no-arrow'>
                            {t('footer.tiktok')}
                        </a>
                        <a href={SOCIAL_LINKS.X} target='_blank' rel="noreferrer" className='nav-link-no-arrow'>
                            {t('footer.x')}
                        </a>

                    </Col>
                    <Col md={3} />
                </Row>
                <Row className='justify-content-center my-2'>
                    <Col>
                        <p
                            style={{ fontSize: '12px' }}
                        >© 2015 - {new Date().getFullYear()} {t('footer.rights')}</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default withTranslation()(Footer);
