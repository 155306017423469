import SingleStay0 from "../assets/projects/StayHittin/floorplans/Single Stay level 0-min.png";
import SingleStay1 from "../assets/projects/StayHittin/floorplans/Single Stay level 1-min.png";
import DoubleStay0 from "../assets/projects/StayHittin/floorplans/Double Stay level 0-min.png";
import DoubleStay1 from "../assets/projects/StayHittin/floorplans/Double Stay level 1-min.png";
import FamilyStay0 from "../assets/projects/StayHittin/floorplans/Family Stay level 0-min.png";
import FamilyStay1 from "../assets/projects/StayHittin/floorplans/Family Stay level 1-min.png";
import FamilyStay2 from "../assets/projects/StayHittin/floorplans/Family Stay level 2-min.png";

import digaram from "../assets/projects/StayHittin/diagrams/diagram.gif";

const images = require.context("../assets/projects/StayHittin/images", false, /.*\.(png|jpe?g|svg)$/);
const thumbnailImages = require.context("../assets/projects/StayHittin/thumbnails", false, /.*\.(png|jpe?g|svg)$/);
const importedImages = images.keys().map(images);
const importedThumbnailImages = thumbnailImages.keys().map(thumbnailImages);

export const StayHittin = {
    id: "StayHittin",
    name: "Stay at Hittin",
    residential_Unit_Type: "Apartments",
    scope: "Design & Develop",
    status: "Rent",
    starting_price: null,
    starting_price_text: null,
    number_of_bedrooms: 3,
    location: "Hittin",
    area: "2,165 m²",
    stats: {
        units: {
            value: 16,
            unit: "",

        },
        built_up_area_per_unit: {
            prefix: "Up to",
            value: 198,
            unit: "m²",
        },
        floors_in_unit: {
            value: [2, 3],
            unit: "",

        },
        total_built_up_area: {
            value: "2,165",
            unit: "m²",
        }
    },
    nearby_description: "",
    team: [
        "Abdulaziz Alkassim",
        "Deema Alkassim",
        "Abdulrahman Alshawbaki",
    ],
    main_images: [
        importedImages[1],
        importedImages[0],
        importedImages[2],
    ],
    text_sections: [
        "STAY is a Build to Rent Residential real estate development & multifamily property management company providing communities with exceptional facilities & amenities along numerous semi-furnished & furnished floor plans offering various lease periods that determine where you want to STAY since 2015.",
        "STAY at Hittin a visionary project, where it offers a distinctive living experience addressing the current housing needs with modern and sleek design. The project is situated in close proximity with the largest entertainment boulevards, business headquarters, centers in Riyadh, offering residents easy access to prime landmarks. The development focuses on providing residents with a functional, aesthetic space complementing the needs of a modern family home, with sustainability features.",
        "The project’s design stands out with a unique double façade, that is distinct to the surrounding architecture, reflecting the STAY’s approach to sustainability standards in design. Inside the building, the residents are welcomed by a landscaped courtyard acting as a buffer to separate units, creating a luminous and inviting atmosphere with distinct view from all the three stories of the building. The development offers 16 units with maximum optimization of spaces, for increased resident comfort and well-being. The apartments range from one, two, and three bedrooms, featuring a comfortable living area, dining area, kitchen, and washrooms. For entertainment and relaxation, the project features amenities such as a rooftop pool, outdoor barbeque and seating areas, co-working spaces, recreational clubhouse. And the rooftop offers panoramic views to the surrounding entertainment zones, and bustling neighborhoods.",
    ],
    section_images: {
        1: [digaram],
    },
    plans_cover: FamilyStay1,
    plnas_description: "The plans efficiently utilize space, offering clearly defined areas for living, dining, cooking, and relaxation. Each space is designed to be adaptable and multifunctional, catering to diverse needs and activities.",
    location_dev: {},
    images: importedImages.map((image, index) => {
        return {
            image: image,
            description: "",
            thumbnail: importedThumbnailImages[index],
        }
    }, []),
    hasPlansOverlay: false,
    hasPlans: true,
    hasLocation: false,
    forSale: false,
    plans: {},
    villaInof: {},
    globalDescriptionList: [
        "1. Living Room",
        "2. Dining Area",
        "3. Kitchen",
        "4. Stairs",
        "5. Laundry",
        "6. W.C",
        "7. Bedroom",
        "8. Outdoor Area",
    ],
    floorPlans: [
        {
            name: "Single Stay Ground Floor",
            img: SingleStay0,
            area: "",
            descriptionList: []
        },
        {
            name: "Single Stay First Floor",
            img: SingleStay1,
            area: "",
            descriptionList: []
        },
        {
            name: "Double Stay Ground Floor",
            img: DoubleStay0,
            area: "",
            descriptionList: []
        },
        {
            name: "Double Stay First Floor",
            img: DoubleStay1,
            area: "",
            descriptionList: []
        },
        {
            name: "Family Stay Ground Floor",
            img: FamilyStay0,
            area: "",
            descriptionList: []
        },
        {
            name: "Family Stay First Floor",
            img: FamilyStay1,
            area: "",
            descriptionList: []
        },
        {
            name: "Family Stay Second Floor",
            img: FamilyStay2,
            area: "",
            descriptionList: []
        },
    ],
};