
import diagram from "../assets/projects/AlUyaynahRetreat/diagrams/diagram.gif";
import floor from "../assets/projects/AlUyaynahRetreat/floorplans/floor.png";

const images = require.context("../assets/projects/AlUyaynahRetreat/images", false, /.*\.(png|jpe?g|svg)$/);
const thumbnailImages = require.context("../assets/projects/AlUyaynahRetreat/thumbnails", false, /.*\.(png|jpe?g|svg)$/);
const importedImages = images.keys().map(images);
const importedThumbnailImages = thumbnailImages.keys().map(thumbnailImages);

export const AlUyaynahRetreat = {
    id: "AlUyaynahRetreat",
    name: "Al Uyaynah retreat",
    residential_Unit_Type: "Retreat",
    scope: "Design & Develop",
    status: "Sold",
    starting_price: null,
    starting_price_text: null,
    number_of_bedrooms: null,
    location: "Al Uyaynah",
    area: "10,000 m²",
    stats: {
        area: {
            value: "10,000",
            unit: "m²"
        },
        total_built_up_area: {
            value: 450,
            unit: "m²"
        },
    },
    nearby_description: "",
    team: [
        "Abdulaziz Alkassim",
        "Deema Alkassim",
    ],
    main_images: [
        importedImages[4],
        importedImages[1],
        importedImages[2],
    ],
    text_sections: [
        "A weekend home project, it was designed to create an indoor/outdoor experience that takes advantage of the site's strengths and opportunities, while mitigating its weaknesses and threats. The property divides the areas depending on functions, totaling the built-up area to 450 m².",
        "The muted façade greets users as it shields from the sun, light-polluted skies, and the gaze of neighboring buildings. It is a stark contrast to the landscape around, and it drives focus to the scenery as opposed to man-made elements. The canopy embraces a discreet opening behind the steps, inviting exploration with humble charm. However, the spaces with glass walls within contradict this sense of modesty, creating a sense of grandeur and connection to outdoors. The canopy was positioned based on the sun's trajectory, casting a functional and visually appealing shadow on the facade.",
        "A central courtyard with a tree separates living spaces on either side. The main living area depicts the celebration of Saudi heritage and culture, followed by a relaxed and inviting dining area. The overhanging roofs merge together behind the tree, stitching the two spaces into a unified whole. They also provide shade and protection, creating a seamless transition between indoor and outdoor living. The spaces are strategically located in the northeast orientation to exploit the palm tree line and hill view. This provides a sense of serenity, tranquility, openness, and connection to nature.",
        "The pool is located along the same view frame to create a sense of balance and harmony. The pool also serves to emphasize the name of Al Uyaynah, which means \"A natural spring of water.\" It also contrasts with the hills in color and texture, creating a visually interesting and dynamic space. The current of air, cooled by the pool, flows in to the courtyard and out through the rooftop opening, achieving natural ventilation. The pool overflows to create a water feature for the outdoor sitting area below, providing a relaxed atmosphere. The water feature and the vegetation create a tropical oasis for the outdoor sitting area. The vegetation also provides shade and privacy, and the water feature creates a sense of movement and sound.",
        "To ensure no threats to the user experience, the project is nestled in a grove of trees."
    ],
    section_images: {
        1: [diagram],
    },
    plans_cover: floor,
    plnas_description: "The plan prioritizes user engagement with the natural environment. This is achieved through an intentional design that encourages movement between spaces via outdoor pathways. This approach fosters a deeper connection with nature and enhances the overall user experience.",
    location_dev: {},
    images: importedImages.map((image, index) => {
        return {
            image: image,
            description: "",
            thumbnail: importedThumbnailImages[index],
        }
    }, []),
    hasPlansOverlay: false,
    hasPlans: true,
    hasLocation: false,
    forSale: false,
    plans: {},
    villaInof: {},
    floorPlans: [
        {
            name: "Main Building",
            img: floor,
            area: "",
            descriptionList: [
                "Main Building",
                "1 Living Area",
                "2 Lounge & Dining Area",
                "3 W.C.",
                "",
                "Outdoor Areas:",
                "4 Outdoor Kitchen",
                "5 Pool",
                "6 Storage",
            ],
        }
    ],
};