import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageMap, Area } from '@qiuz/react-image-map';

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import ArrowLink from '../ArrowLink/ArrowLink';
import { Row, Col } from 'react-bootstrap';
import { APP_ROUTES } from '../../const/const';
import DoorButton from '../DoorButton/DoorButton';
import NotFound from '../NotFoundPage/NotFound';
import FloorPlan from '../FloorPlan/FloorPlan';

import { Projects } from '../../Projects/Main';
import { Projects_ar } from "../../Projects/Main";

import { useLocation, useNavigate } from 'react-router-dom';


import './FloorPlans.css';


const FloorPlans = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    let ProjectsList = Projects;
    if (i18n.language === "ar") {
        ProjectsList = Projects_ar;
    }

    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 992);

    const queryParams = new URLSearchParams(location.search);
    const project_id = queryParams.get("project_id");
    const project = ProjectsList.find(project => project.id === project_id);

    const [showOverview, setShowOverview] = React.useState(project.hasPlansOverlay);
    const [showFloorPlans, setShowFloorPlans] = React.useState(!project.hasPlansOverlay);
    const [showIndividualFloorPlan, setShowIndividualFloorPlan] = React.useState(false);

    const [hoveredVilla, setHoveredVilla] = React.useState(0);
    const [selectedFloorPlan, setSelectedFloorPlan] = React.useState(0);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 992);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    if (!project || !project.hasPlans) {
        return (
            <NotFound />
        );
    }


    const mapArea = [
        {
            left: '25%',
            top: '60%',
            height: '22%',
            width: '49%',
            onMouseOver: (event) => handleMouseOver(event),
            onMouseLeave: () => setHoveredVilla(0),
            style: {
                cursor: 'pointer'
            }
        },
        {
            left: '25%',
            top: '38%',
            height: '22%',
            width: '49%',
            onMouseOver: (event) => handleMouseOver(event),
            onMouseLeave: () => setHoveredVilla(0),
            style: {
                cursor: 'pointer'
            }
        },
        {
            left: '25%',
            top: '16%',
            height: '22%',
            width: '49%',
            onMouseOver: (event) => handleMouseOver(event),
            onMouseLeave: () => setHoveredVilla(0),
            style: {
                cursor: 'pointer'
            }
        },
    ];

    const onMapClick = (area, index) => {
        window.scrollTo(0, 0);
        setShowOverview(false);
        setShowFloorPlans(true);
    }

    const handleMouseOver = (event) => {
        const index = Array.from(event.target.parentNode.children).indexOf(event.target);
        setHoveredVilla(index);
    }

    const onClickFloorPlan = (index) => {
        window.scrollTo(0, 0);
        setSelectedFloorPlan(index);
        setShowFloorPlans(false);
        setShowIndividualFloorPlan(true);
    }

    return (
        <>
            <Navbar />
            <Row className={'gallery-header'}>
                <Col xs={3} md={3} lg={3} className={'gallery-header-col d-none d-sm-none d-md-none d-lg-block'}>
                    <ArrowLink text={t('project_details.back')}
                        dir='left'
                        className={'project-details-view-more'} onClick={() => {
                            if (showOverview) {
                                navigate(APP_ROUTES.PROJECT_DETAILS + '?project_id=' + project.id);
                            } else if (showFloorPlans) {
                                if (project.hasPlansOverlay) {
                                    setShowOverview(true);
                                    setShowFloorPlans(false);
                                } else {
                                    navigate(APP_ROUTES.PROJECT_DETAILS + '?project_id=' + project.id);
                                }
                            } else if (showIndividualFloorPlan) {
                                setShowFloorPlans(true);
                                setShowIndividualFloorPlan(false);
                            }
                        }
                        } />
                </Col>
                <Col xs={12} md={12} lg={6} className={'gallery-header-col'}>
                    <h3>{project.name}</h3>
                </Col>
                <Col xs={12} md={12} lg={3} className={'gallery-header-col'}>
                    {project.forSale && (
                        <DoorButton
                            className={'book-a-tour-button'}
                            text={t('project_details.book_a_tour')} href={APP_ROUTES.BOOK_A_TOUR + '?project_id=' + project.id} />
                    )}
                </Col>
            </Row>

            <div className='overview' style={{ display: showOverview ? 'block' : 'none' }}>
                {project.hasPlansOverlay && (
                    <>
                        <Row style={{ position: 'relative' }}>
                            <Col>
                                <ImageMap
                                    className="plan-image"
                                    src={project.plans.cover}
                                    map={mapArea}
                                    onMapClick={onMapClick}
                                    style={hoveredVilla === 0 ?
                                        { opacity: 1 } : { opacity: 0 }
                                    }
                                />

                                <img
                                    src={project.plans.villa1}
                                    alt="villa3"
                                    className="villa-image"
                                    style={{
                                        display: hoveredVilla === 1 ? 'block' : 'none',
                                    }}
                                />
                                <img
                                    src={project.plans.villa2}
                                    alt="villa2"
                                    className="villa-image"
                                    style={{
                                        display: hoveredVilla === 2 ? 'block' : 'none',
                                    }}
                                />
                                <img
                                    src={project.plans.villa3}
                                    alt="villa3"
                                    className="villa-image"
                                    style={{
                                        display: hoveredVilla === 3 ? 'block' : 'none',
                                    }}
                                />
                            </Col>
                        </Row>


                        <Row className='villa-info'>
                            <Col>
                                <p
                                    onMouseEnter={() => setHoveredVilla(1)}
                                    onMouseLeave={() => setHoveredVilla(0)}
                                    onClick={() => { onMapClick(null, 1) }}
                                    className={`villa-info-title ${hoveredVilla === 1 ? 'villa-info-hovered' : ''} `}
                                >{t('floor_plans.villa')} 1</p>
                                <p>{project.villaInof.villa1.size} {t('common.sqm')}</p>
                                <p>{project.villaInof.villa1.availabile ? project.villaInof.villa1.price_text : t('floor_plans.sold')}</p>
                            </Col>
                            <Col>
                                <p
                                    onMouseEnter={() => setHoveredVilla(2)}
                                    onMouseLeave={() => setHoveredVilla(0)}
                                    onClick={() => { onMapClick(null, 2) }}
                                    className={`villa-info-title ${hoveredVilla === 2 ? 'villa-info-hovered' : ''} `}
                                >{t('floor_plans.villa')} 2</p>
                                <p
                                    className='villa-info-info'
                                >{project.villaInof.villa2.size} {t('common.sqm')}</p>
                                <p
                                    className='villa-info-info'
                                >{project.villaInof.villa2.availabile ? project.villaInof.villa2.price_text : t('floor_plans.sold')}</p>
                            </Col>
                            <Col>
                                <p
                                    onMouseEnter={() => setHoveredVilla(3)}
                                    onMouseLeave={() => setHoveredVilla(0)}
                                    onClick={() => { onMapClick(null, 3) }}
                                    className={`villa-info-title ${hoveredVilla === 3 ? 'villa-info-hovered' : ''} `}
                                >{t('floor_plans.villa')} 3</p>
                                <p
                                    className='villa-info-info'
                                >{project.villaInof.villa3.size} {t('common.sqm')}</p>
                                <p
                                    className='villa-info-info'
                                >{project.villaInof.villa3.availabile ? project.villaInof.villa3.price_text : t('floor_plans.sold')}</p>
                            </Col>
                        </Row>
                    </>
                )}
            </div>


            <Row className='floor-plans' style={{ display: showFloorPlans ? 'flex' : 'none' }}>
                {project.floorPlans.map((floorPlan, index) => {
                    return (
                        <Col key={index} className='floor-plan' xs={12} md={12} lg={3}>
                            <FloorPlan
                                img={floorPlan.img}
                                name={floorPlan.name}
                                area={floorPlan.area}
                                descriptionList={(floorPlan.descriptionList.length == 0 && project.globalDescriptionList != null) ? project.globalDescriptionList : floorPlan.descriptionList}
                                mode={isMobile ? 'individual' : 'overview'}
                                showCloseButton={false}
                                onClick={() => { onClickFloorPlan(index) }}
                            />
                        </Col>
                    );
                }
                )}



            </Row>

            <Row className='individual-floor-plan' style={{ display: showIndividualFloorPlan ? 'block' : 'none' }}>
                <Col>
                    <FloorPlan
                        img={project.floorPlans[selectedFloorPlan].img}
                        name={project.floorPlans[selectedFloorPlan].name}
                        area={project.floorPlans[selectedFloorPlan].area}
                        descriptionList={(project.floorPlans[selectedFloorPlan].descriptionList && project.globalDescriptionList != null) ? project.globalDescriptionList : project.floorPlans[selectedFloorPlan].descriptionList}
                        mode='individual'
                        onClick={() => { }}
                        onClose={() => {
                            setShowFloorPlans(true);
                            setShowIndividualFloorPlan(false);
                        }}
                    />
                </Col>


            </Row>

            <Footer />
        </>
    );
};

export default FloorPlans;
