const images = require.context("../assets/projects/StayKSU/images", false, /.*\.(png|jpe?g|svg)$/);
const thumbnailImages = require.context("../assets/projects/StayKSU/thumbnails", false, /.*\.(png|jpe?g|svg)$/);
const importedImages = images.keys().map(images);
const importedThumbnailImages = thumbnailImages.keys().map(thumbnailImages);


export const STAYKSU = {
    id: "STAYKSU",
    name: "Stay KSU",
    residential_Unit_Type: "Apartments",
    scope: "Develop",
    status: "Pending",
    starting_price: null,
    starting_price_text: null,
    number_of_bedrooms: null,
    location: "King Saud Campus",
    area: "123,550 m²",
    rotate_images: false,
    stats: {
        units: {
            value: "+300",
            unit: "",
        },
        total_built_up_area: {
            value: "20,000",
            unit: "m²",
        }
    },
    nearby_description: "Located within King Saud University Campus.",
    team: [],
    main_images: [
        importedImages[0]
    ],
    text_sections: [
        "STAY is a Build to Rent Residential real estate development & multifamily property management company providing communities with exceptional facilities & amenities along numerous semi-furnished & furnished floor plans offering various lease periods that determine where you want to STAY since 2015.",
        "STAY at King Saud University Campus is a visionary project, where it offers a distinctive living experience addressing the current housing needs with modern and sleek design. The project is situated within Saudi's biggest university campus, and in close proximity with the largest business headquarters, and centers in Riyadh, offering residents easy access to prime landmarks. The development focuses on providing residents with a functional, aesthetic space complementing the needs of a modern home, with sustainability features.",
        "STAY's first mixed-use development, combining both residential and commercial uses within the project. The development offers 300+ units with maximum optimization of spaces, for increased resident comfort and well-being. The units vary from apartments, lofts, and townhomes.",
    ],
    section_images: {},

    plans_cover: null,
    plnas_description: "The plans efficiently utilize space, offering clearly defined areas for living, dining, cooking, and relaxation. Each space is designed to be adaptable and multifunctional, catering to diverse needs and activities.",
    location_dev: {
        latitude: 24.735128,
        longitude: 46.623833,
        google_maps_url: "https://maps.app.goo.gl/o5rCDCaJFSAdHHqg8",
    },
    images: importedImages.map((image, index) => {
        return {
            image: image,
            description: "",
            thumbnail: importedThumbnailImages[index],
        }
    }, []),
    hasPlansOverlay: false,
    hasPlans: false,
    hasLocation: true,
    forSale: false,
    plans: {},
    villaInof: {},
    floorPlans: [],
};