import img1 from "../assets/projects/StayAlJubayla/images/1.jpg";
import img2 from "../assets/projects/StayAlJubayla/images/2.jpg";
import img3 from "../assets/projects/StayAlJubayla/images/3.jpg";
import img4 from "../assets/projects/StayAlJubayla/images/4.jpg";
import img5 from "../assets/projects/StayAlJubayla/images/5.jpg";
import img6 from "../assets/projects/StayAlJubayla/images/6.jpg";
import img8 from "../assets/projects/StayAlJubayla/images/8.jpg";
import img9 from "../assets/projects/StayAlJubayla/images/9.jpg";
import img10 from "../assets/projects/StayAlJubayla/images/10.jpg";
import img11 from "../assets/projects/StayAlJubayla/images/11.jpg";
import img12 from "../assets/projects/StayAlJubayla/images/12.jpg";


import thm1 from "../assets/projects/StayAlJubayla/thumbnails/1_tn.jpg";
import thm2 from "../assets/projects/StayAlJubayla/thumbnails/2_tn.jpg";
import thm3 from "../assets/projects/StayAlJubayla/thumbnails/3_tn.jpg";
import thm4 from "../assets/projects/StayAlJubayla/thumbnails/4_tn.jpg";
import thm5 from "../assets/projects/StayAlJubayla/thumbnails/5_tn.jpg";
import thm6 from "../assets/projects/StayAlJubayla/thumbnails/6_tn.jpg";
import thm8 from "../assets/projects/StayAlJubayla/thumbnails/8_tn.jpg";
import thm9 from "../assets/projects/StayAlJubayla/thumbnails/9_tn.jpg";
import thm10 from "../assets/projects/StayAlJubayla/thumbnails/10_tn.jpg";
import thm11 from "../assets/projects/StayAlJubayla/thumbnails/11_tn.jpg";
import thm12 from "../assets/projects/StayAlJubayla/thumbnails/12_tn.jpg";

import plan from "../assets/projects/StayAlJubayla/floorplanes/Floor Plan.jpg";


export const StayAlJubayla = {
    id: "StayAlJubayla",
    name: "Stay AlJubayla",
    residential_Unit_Type: "Apartments",
    scope: "Develop",
    status: "Pending",
    location: "AlJubayla",
    area: "1,599 m²",
    stats: {
        units: {
            value: 54,
            unit: "",
        },
        floors_in_unit: {
            value: 1,
            unit: "",
        },
        built_up_area_per_unit: {
            value: 69,
            unit: "m²",
        },
        plot_area_per_unit: {
            value: "10,255",
            unit: "m²",
        },
    },
    nearby_description: "The project is located by Riyadh's most idyllic farmlands, this project offers a unique blend of urban convenience and rural serenity",
    team: [
        "Abdulaziz Alkassim",
        "Deema Alkassim",
        "Abdulelah Almarzouk",
        "Amna Jazeel",
        "Samia Monirul I."
    ],
    main_images: [
        img1,
        img2,
        img3,
    ],
    text_sections: [
        "STAY at AlJubayla is a project that offers a unique living experience, addressing current housing needs with a modern design. The development focuses on providing residents with functional spaces that meet the demands of a modern home, incorporating sustainability features.",
        "The project's design is distinguished by a unique double façade that sets it apart from the surrounding architecture, reflecting STAY's commitment to sustainability standards in design. Inside, residents are greeted by a landscaped courtyard that acts as a buffer between units, creating a bright and welcoming atmosphere with distinct views from all three stories of the building. The development includes 54 units, optimized for maximum resident comfort and well-being. The apartments range from one to two bedrooms and feature a comfortable living area, dining area, kitchen, and washrooms.",
        "The Stay AlJubayla Project is a serene development designed to offer a tranquil escape with modern amenities. This project is crafted to provide comfort, making it an ideal choice for those seeking a change of scenery.",
        "The project offers a variety of retail options, ensuring that residents have everything they need within reach.",
        "The environment is designed for a getaway in Riyadh nature while providing the conveniences of modern living. Whether you're looking to unwind in a serene setting or enjoy the vibrant community amenities, Stay AlJubayla Project caters to diverse lifestyle needs."
    ],
    section_images: {},
    plans_cover: plan,
    plnas_description: "The layouts make optimal use of space, providing distinct zones for living, dining, cooking, and relaxation. Each area is crafted to be versatile and multifunctional, accommodating a variety of needs and activities.",
    location_dev: {
        latitude: 24.901834,
        longitude: 46.435208,
        google_maps_url: "https://maps.app.goo.gl/iJfxF5aCmgiLFih76?g_st=iw",
    },
    images: [
        {
            image: img1,
            thumbnail: thm1,
        },
        {
            image: img2,
            thumbnail: thm2,
        },
        {
            image: img3,
            thumbnail: thm3,
        },
        {
            image: img4,
            thumbnail: thm4,
        },
        {
            image: img5,
            thumbnail: thm5,
        },
        {
            image: img6,
            thumbnail: thm6,
        },
        {
            image: img8,
            thumbnail: thm8,
        },
        {
            image: img9,
            thumbnail: thm9,
        },
        {
            image: img10,
            thumbnail: thm10,
        },
        {
            image: img11,
            thumbnail: thm11,
        },
        {
            image: img12,
            thumbnail: thm12,
        },
    ],
    hasPlansOverlay: false,
    hasPlans: true,
    hasLocation: true,
    forSale: false,
    plans: {},
    floorPlans: [
        {
            name: "unit",
            img: plan,
            area: "69 m²",
            descriptionList: [
                "1 Bedroom",
                "2 Living Area",
                "3 Dining Area",
                "4 Washroom",
                "5 Kitchen",
            ],
        },
    ],
};
